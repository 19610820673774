.rooms {
  margin: 5rem auto;
  padding: 1rem;
  width: 100%;
  background-color: #fff;
}

.rooms .container {
  width: 1240px;
  margin: auto;
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
}

.rooms .col-1 {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.rooms .col-1 p {
  font-size: 1.8rem;
  font-style: italic;
}
.rooms .container .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3rem;
    transition: .10s ease-in;
}

.rooms .card {
  padding: 1rem;
  text-align: center;
  height: 450px;
  width: 100%;
}
.room__cards{
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center; 
  align-items: center;
  margin-bottom: 20px;
}
.rooms h2 {
  font-size: 3rem;
  color: var(--h-color);
  margin: 50px 0 50px 42px;
  padding-left: 0rem;
}
.rooms h4 {
  color: var(--h-color);
  user-select: none;
  letter-spacing: 0.1rem;
  font-weight: 900;
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: -1;
}
.room-img-cointainer {
  width: 100%;
  height: 218px;
  overflow: hidden;
  position: relative;
  margin-bottom: 30px;
}
.room-Img {
  width: 300px;
  height: 200px;
  opacity: 1;
  transition: 0.3s ease-out;
  object-fit: cover;
  
}
.room-Img:hover {
  opacity: .5;
  transition: 0.5s ease-in;
  cursor: pointer;
}

.rooms .card p {
  color: #787878;
  font-size: 1.1rem;
  margin: 25px 0;
  width: 100%;
  height: 60px;

}

.rooms .card  .price-tag{
  height: 20px;
}
.rooms .card button{
  /* width: 8rem; */
height: auto;
font-size: 14px;
padding: 10px 10px;
}

.rooms .card span {
  color: var(--h-color);
  font-size: 1rem;
  font-weight: 700;
}

.rooms .col-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (max-width: 940px) {
    .rooms .container .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        transition: .10s ease-in;
    }
    .rooms .container {
        width: 100%;
        margin: auto;
        display: grid;
        /* grid-template-columns: repeat(2, 1fr); */
      }


.rooms .container .content {
padding: 0;
}
.room__cards {
 
  margin-bottom: 129px;
}
.rooms h4 {
z-index: 0;
color: #fff;
}
.rooms h2 {
  font-size: 2rem;
  letter-spacing: 0.8rem
}

.rooms .card p {
  width: 28ch;
}
}




