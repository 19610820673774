.image__slider__container {
  width: 100%;
  height: 400px;
}
.slider__img {
  width: 400px;
  height: 400px;
  object-fit: cover;
}
/* .carousel-status{
    display: none!important;
} */

@media screen and (max-width: 940px) {
    .image__slider__container {
      width: 100%;
      height:  300px;
      }
      .slider__img {
        
        height: 250px;
        object-fit: cover;
      }
    .carousel .slide img {
        object-fit: cover;
        height: 175px !important;
    }
}