.room__info__container {
  margin: 20px;
  padding: 20px;
}

.room__info__hedding {
  margin: 10px 0;
  text-align: center;
}
.room__info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.info__list__card{
  width: 259px;
  background-color: rgba(1, 0, 10, 0.027);
  text-align: center;
  padding: 20px;
  margin: 20px 10px;
}

.pannellum__container{
width: 50%;
margin: 20px 0;
}
.room__info .slider__container{
  width: 50%;
margin: 10px;
}
.room__info .panaroma__img {
  width: 100% !important;
}
.pnlm-controls-container {
  display: none !important;
}

.room__info__container .container {
  width: 100%;
  margin: 50px 0;
}
.info {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  text-align: left;
}
.info__items {
  min-width: 200px;
  /* max-height: 100px; */

}

.info__list h5 {
  font-size: 14px;
  margin: 10px 0;
}
.info__items li {
  font-size: 12px;
  margin: 5px 0;
}

@media screen and (max-width: 940px) {
  
  .room__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .room__info__container {
    margin: 0;
}
  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    align-items: center;
  }
  .info__items {
    width: 100%;
    padding: 10px;
  }

.pannellum__container {
  width: 100%;
  margin: 10px 0;
}
.room__info__container .button{
  margin: 10px 0;
}
}
