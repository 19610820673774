.experiences {
  margin: auto;
  padding: 5rem 1rem 0 1rem;
  width: 100%;
  margin-bottom: 100px;
  background-color: #fff;
}
.experiences .intor {
  margin: 30px 0;
}

.experiences .container {
  width: 100%;
  padding: 4rem 40px;
}

.experiences .container .content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin: 4rem 0;
}

.experiences .card {
  border: 1px solid #eee;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgb(0, 0, 0, 0.2);
  padding: 1rem;
}

.experiences p {
  color: var(--p-color);
  letter-spacing: 0.1rem;
  font-size: 1rem;
  font-weight: 100;
  line-height: 2rem;
  max-width: 60ch;
}

.experiences .card span {
  color: var(--primary-color);
  font-size: 1rem;
  font-weight: 700;
}

@media screen and (max-width: 940px) {
  .experiences {
    padding: 5rem 1rem 1rem 1rem;
  }
  .experiences .container {
    width: 100%;
    padding: 0rem 10px;
  }

  .experiences .container .content {
    grid-template-columns: 1fr;
  }
  .experiences p {
    max-width: 80ch;
  }

  .experiences .sub__hedding {
    margin: 50px 0 0 0;
  }
  .experiences .card {
    max-width: 100%;
    margin: 1rem;
  }
  .carousel .slide img {
    height: 200px;
  }

  .experiences .image__slider__container {
    width: 100%;
    height: 437px;
  }
}
