:root {
  --primary-color: #C78022;
  --BG-color: #000;
  --primary-light: #eca13e;
  --secondary-color: #ffffff;
  --secondary-dark: #e3d002;
  --overlay: rgba(0, 0, 0, 0.562);
  --buttonColor: #000;
  --h-color: #000;
  --p-color: #1b1b1b;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
  line-height: 1.2;
  background-color: #ffffff;
}
.control-arrow{
  z-index: -2 !important;
}
.control-dots {
  z-index: -1 !important;
}
 

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.section__hedding {
  font-size: 3rem;
  color: var(--h-color);
  margin: 50px 0 50px 42px;
  padding-left: 1rem;
  letter-spacing: .5rem;
}
.sub__hedding {
  /* color: var(--h-color); */
  color: var(--primary-light);
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 30px;
  letter-spacing: .5rem;
}


.button {
  padding: 10px 28px;
  font-size: 1rem;
  color: var(--buttonColor);
  font-weight: 400;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid var(--primary-color);
  cursor: pointer;
  transition: 0.2s ease-in;
  /* max-width: 200px; */
  width: 300px;
}

.button:hover {
  background-color: var(--buttonColor);
  border: 1px solid var(--buttonColor);
  color: var(--secondary-color);
}

.line {
  display: block;
  width: 40px;
  height: 3px;
  margin: 5px auto;
  background-color: var(--primary-color);
}
.show_more_room_info {
  position: fixed;
  top: 0%;
  left: 0%;
  /* transform: translate(-50%, -50%); */
  width: 100%;
  height: 100%;
  background-color: var(--secondary-color);
  z-index: 10000;
  transition: 0.5s ease;
  overflow: hidden;
  scroll-behavior: smooth;
  overflow-y: auto;
}
/* .show_more_room_info::-webkit-scrollbar{
 display: none;
} */
.close-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px !important;
  font-weight: 700 !important;
  background-color: var(--buttonColor);
  color: var(--secondary-color);
  letter-spacing: 0.5rem;
}
.close-btn:hover {
  color: #787878;
  transition: 0.3s ease;
}

.seperator{
  width: 100%;
  height: 300px;
  background-color: red;
  position: fixed;
  z-index: -100;
}


@media screen and (max-width: 940px) {
  .section__hedding {
    font-size: 2rem;
    text-align: center;
    margin: 50px 0;
    padding-left: 0rem;
    letter-spacing: .5rem;
  }
  .sub__hedding {
    color: var(--h-color);
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 30px;
    letter-spacing: .5rem;
    text-align: center;
  }
}
