.hero {
    background-color: var(--BG-color);
    color: #fff;
    height: 100vh;
    width: 100%;
    position: relative;
    margin-bottom: 100px;
}

/* .hero:before {
    content: '';
    background: url('./images/main/hero.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
} */
#background-video{

    height: 100%;
    display: block;
    width: 100%;
    float: left;
    top: 0;
    padding: none;
    position: absolute;
    object-fit: cover;
    }
.hero .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    max-width: 1100px;
    margin: 0 80px;
    padding: 1rem;
    position: absolute;
    right: 0;
}



.hero .content p{
    font-family: 'Roboto', sans-serif;
    font-size: 1.9rem;
    font-weight: 300;
    letter-spacing: .3rem;
    margin-bottom: 40px;
    text-transform: uppercase;
}

.hero .button {
    font-family: 'Roboto', sans-serif;
    text-align: center;
    font-weight: 300;
    margin: 1rem 0;
    width: 250px;
    background-color: transparent;
    border: 1px solid var(--secondary-color);
    color: #fff;
    letter-spacing: .3rem;
    border-radius: 0;
}

.hero .button:hover {
    color: var(--primary-light);
    /* background-color: var(--primary-light); */
    border: 1px solid var(--primary-light);
}

@media screen and (max-width:940px) {
    .hero .content {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        -webkit-align-items: center;
        justify-content: flex-end;
        -webkit-justify-content: flex-end;
        max-width: 1100px;
        margin: auto;
        padding: .5rem;
        padding-bottom: 175px;
    }
    .hero .content p {
        font-size: 2rem;
        text-transform: uppercase;
        line-height: 45pt;
        text-align: center;
    }
}
@media screen and  (max-width:940px) and (orientation:landscape){
    .hero .content {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        -webkit-align-items: center;
        justify-content: flex-end;
        -webkit-justify-content: flex-end;
        max-width: 1100px;
        margin: auto;
        padding: .5rem;
        padding-bottom: 0;
      }
      .hero .content p {
        margin-bottom: 10px;
      }
      .hero .button {
        margin: 2rem 0; 
      }

}









