.header {
    position: fixed;
    height: 90px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
    background-color: rgba(0,0,0,.9);
}
.logo{
    width: 80px;
}

.header .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1240px;
    margin: auto;
    height: 100%;
   
}

.header .nav-menu a {
    color: #ffffff;
    cursor: pointer;
}

/* .header .navbar img {
    width: 250px;
    height: auto;
} */

.header .nav-menu {
    display: flex;
}

.header .nav-item {
    padding: 1rem;
    font-weight: 500;
}
.nav-item .active{
    padding-bottom: 12px;
    border-bottom: 3px solid var(--primary-color);
}

.header .nav-item a:hover {
    color: var(--secondary-color);
}

.hamburger {
    display: none;
}

.logo{
    width: 60px;
    stroke-dasharray: 280;
    stroke-dashoffset: 800;
    animation: animate-logo 4s ease forwards;
}

  @keyframes animate-logo {
    to {
      stroke-dasharray: 800;
      stroke-dashoffset: 5;
  
    }
  }
  /* .logo__h{
   
    stroke-dasharray: 444;
    stroke-dashoffset: 444;
    animation: animate-logo-h 4s ease infinite;
}

  @keyframes animate-logo-h {
    to {
      stroke-dasharray: 253;
      stroke-dashoffset: 0;
  
    }
  } */


@media screen and  (max-width:940px) {
    .header {
        max-width: 100%;
        background-color: rgba(0,0,0,.9);
    }

    .header .navbar {
        max-width: 940px;
        padding: 0 1rem;
    }
  .logo{
      width: 60px;
  }

      

    .hamburger {
        display: block;
    }

    .nav-menu {
        position: fixed;
        left: -100%;
        top: 90px;
        flex-direction: column;
        background-color: rgba(0,0,0,.9);
        width: 100%;
        height: 90vh;
        z-index: 999;
        text-align: center;
        transition: .3s;
        padding-top: 90px;
    }

    .header .nav-item {
        padding: 1.5rem;
        font-weight: 500;
      }
      .nav-menu.active {
        left: 0;
    }



   
}
@media screen and  (max-width:940px) and (orientation:landscape){
    .nav-menu {
        position: fixed;
        left: -100%;
        top: 90px;
        flex-direction: row;
        background-color: rgba(0,0,0,.9);
        width: 100%;
        height: 90vh;
        z-index: 999;
        text-align: center;
        transition: .3s;
        padding-top: 0;
        justify-content: center;
       
        
    }
    .header .nav-item {
        align-self: flex-start;
        margin-top: 50px;
       
    }

}

@media screen and (max-width:414px) {
    .header {
        max-width: 414px;
        background-color: rgba(0,0,0,.9);
    }
    .header .nav-menu {
        display: flex;
        width: 100vw;
    }
    
}














