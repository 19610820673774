#contactUs {
  background-color: #fff;
  /* margin: auto;
  padding: 5rem 1rem 0 1rem; */
  width: 100%;
  height: 120vh;
  }
 
 
  .contact-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    padding: 20px;
    position: relative;
    max-width: 840px;
    margin-top: 150px;
    
  }
  #contactUs .contact-wrapper button{
    margin: 10px;
  }
  /* Left contact page */
  .form-horizontal {
    max-width: 400px;
    font-weight: 400;
    display: flex;
flex-direction: column;
align-items: center;
  }
  
  .form-control, 
  textarea {
    width: 400px;
    color: var(--p-color);
    margin: 10px; 
    border:  1px solid var(--primary-color);
    letter-spacing: 1.5px;
  }
  .errorMessage{
    display: flex;
    width: 100%;
    color: rgba(255, 0, 0, 0.301);
    margin: 10px; 
    letter-spacing: 1.5px;
  }
  .form-control, 
  input {
    
    padding: 10px;
    
  }  
  .send-button {
    margin-top: 15px;
    height: 34px;
    width: 400px;
    overflow: hidden;
    transition: all .2s ease-in-out;
    cursor: pointer;
  }
  

  
  /* Begin Right Contact Page */
  .direct-contact-container {
    max-width: 400px;
  }
  
  /* Location, Phone, Email Section */
  .contact-list {
    list-style-type: none;
    padding-right: 20px;
  }
  
  .list-item {
    line-height: 4;
    color: var(--primary-color);
  }
  
  .contact-text {
    font-size: 14px;
    letter-spacing: 1.9px;
    color: var(--primary-color);
  }
  
  .place {
    margin-left: 62px;
  }
  
  .phone {
    margin-left: 56px;
  }
  
  .gmail {
    margin-left: 53px;
  }
  
  .contact-text a {
    color: var(--primary-color);
    text-decoration: none;
    transition-duration: 0.2s;
  }
  
  .contact-text a:hover {
    color: var(--p-color);
    text-decoration: none;
  }
  
  
  /* Social Media Icons */
  .social-media-list {
    position: relative;
    font-size: 22px;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
  
  .social-media-list li a {
    color: var(--primary-color);
  }
  
  .social-media-list li {
    position: relative; 
    display: inline-block;
    height: 60px;
    width: 60px;
    margin: 10px 3px;
    line-height: 60px;
    border-radius: 50%;
    color: #fff;
    /* background-color: rgb(27,27,27); */
    cursor: pointer; 
    transition: all .2s ease-in-out;
  }
  
  .social-media-list li:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 50%;
    opacity: 0;
    box-shadow: 0 0 0 1px #fff;
    transition: all .2s ease-in-out;
  }
  
  .social-media-list li:hover {
    background-color: #fff; 
  }
  
  .social-media-list li:hover:after {
    opacity: 1;  
    transform: scale(1.12);
    transition-timing-function: cubic-bezier(0.37,0.74,0.15,1.65);
  }
  
  .social-media-list li:hover a {
    color: #000;
  }
  
  .copyright {
    font-size:  14px;
    color: #c77f225b;
    letter-spacing: 1px;
    text-align: center;
    margin: 20px;
  }
  
  hr {
    border-color: var(--primary-color);
    margin: 5px;
    border-width: .1px;
  }
  
  /* Begin Media Queries*/
  @media screen and (max-width: 850px) {
    .contact-wrapper {
      display: flex;
      flex-direction: column;
    }
    .direct-contact-container, .form-horizontal {
      margin: 0 auto;
    }  
    
    .direct-contact-container {
      margin-top: 60px;
      max-width: 300px;
    }    
    .social-media-list li {
      height: 60px;
      width: 60px;
      line-height: 60px;
    }
    .social-media-list li:after {
      width: 60px;
      height: 60px;
      line-height: 60px;
    }
    .copyright {
      font-size: 12px;
      
      margin: 12px;
  }
  }
  
  @media screen and (max-width: 569px) {
  
    .direct-contact-container, .form-wrapper {
      float: none;
      margin: 0 auto;
    }  
    .form-control, textarea {
      
      margin: 10px auto;
      width: 280px;
    }
   .button{
     width: 280px;
   }
    
    .name, .email, textarea {
      width: 280px;
    } 
    
    .direct-contact-container {
      margin-top: 60px;
      max-width: 280px;
    }  
    .social-media-list {
      left: 0;
    }
    .social-media-list li {
      height: 55px;
      width: 55px;
      line-height: 55px;
      font-size: 2rem;
    }
    .social-media-list li:after {
      width: 55px;
      height: 55px;
      line-height: 55px;
    }
    
  }
  


