.room__info__slider .carousel-root {
  width: 50%;
  height: 500px;
  margin: 20px 0;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.carousel-slider {
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.carousel .slide img {
  object-fit: cover;
  height: 400px;
}

.carousel img {
  height: 57px;
}

.carousel .thumbs-wrapper {
  justify-content: center;
  position: relative;
  top: 84px;
  opacity: 0.5;
}
.carousel.carousel-slider {
  overflow: inherit !important;
}

@media screen and (max-width: 940px) {
  html:not(.no-js) [data-aos^=fade][data-aos^=fade].aos-animate {
    width: 100%;
}
  .room__info__slider .carousel-root {
    width: 100%;
    height: 480px;
  }
  .carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 100%;
    height: 450px !important;
    transition: height 0.15s ease-in;
  }
  .carousel .thumbs-wrapper {
    position: relative;
    top: 0;
    display: flex;
    justify-content: center;
  }
  .carousel-slider {
    width: 300px;
    height: 450px;
    object-fit: contain;
}
.carousel .slide img {
  object-fit: contain;
  height: 400px !important;
}
}
